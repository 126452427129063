import React, { useEffect, useRef } from "react";
import Footer from "./footer";
import Header from "./header";
import { useLocation } from "react-router-dom";
import useHandleResize from "lib/device/handleResize";
import LoginForm from "components/login/LoginForm";
import { useContext } from "react";
import { Context } from "lib/store";
import SignUpForm from "components/login/SignUpForm";
import styled from "styled-components";
import ForgotPassword from "components/login/ForgotPassword";
import NewPassword from "components/login/NewPassword";
import Verification from "components/login/Verification";

const Navbar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 100;
  transition: transform ease 500ms;
`;

const Main = styled.main`
  padding-top: 80px;
  @media screen and (min-width: 480px) {
    padding-top: 90px;
  }
  @media screen and (min-width: 928px) {
    padding-top: 140px;
  }
`;

const Messenger = styled.div`
  width: 7rem;
  height: 7rem;
  position: fixed;
  z-index: 100;
  bottom: 2rem;
  right: 2rem;
`;

export default function Layout({ children }) {
  const {
    state: { loginState, showLoginModal },
  } = useContext(Context);
  useHandleResize();

  let location = useLocation();

  useEffect(() => {
    if (window && location.search !== "?offer=true") window.scrollTo(0, 0);
  }, [location]);

  // useEffect(() => {
  //   if (location.pathname === "/contact" && location.search === "?offer=true")
  //     return;
  //   if (location.pathname !== "/" && device === "xlg") window.scrollTo(0, 470);
  // }, [location.pathname]);

  const NavbarRef = useRef();

  useEffect(() => {
    if (window) {
      let lastScroll = 0;
      function toggleNavbar() {
        let currentScroll = window.pageYOffset;
        if (currentScroll < 140) return;
        if (currentScroll - lastScroll > 0) {
          NavbarRef.current.style.transform = "translateY(-140px)";
        } else {
          NavbarRef.current.style.transform = "translateY(0px)";
        }
        lastScroll = currentScroll;
      }

      window.addEventListener("scroll", toggleNavbar);

      return () => {
        window.removeEventListener("scroll", toggleNavbar);
      };
    }
  }, []);

  return (
    <>
      <Navbar ref={NavbarRef}>
        <Header />
      </Navbar>
      {loginState === "login" && showLoginModal && <LoginForm />}
      {loginState === "signup" && showLoginModal && <SignUpForm />}
      {loginState === "forgot" && showLoginModal && <ForgotPassword />}
      {loginState === "verification" && showLoginModal && <Verification />}
      {loginState === "new_password" && showLoginModal && <NewPassword />}
      <Main>
        {children}

        <Messenger>
          {/* <Chat /> */}
          {/* <MessengerChat
          pageId="109268111769502"
          themeColor={"#000000"}
          bottomSpacing={300}
          loggedInGreeting="loggedInGreeting"
          loggedOutGreeting="loggedOutGreeting"
          greetingDialogDisplay={"show"}
          debugMode={true}
          onMessengerShow={() => {
            console.log("onMessengerShow");
          }}
          onMessengerHide={() => {
            console.log("onMessengerHide");
          }}
          onMessengerDialogShow={() => {
            console.log("onMessengerDialogShow");
          }}
          onMessengerDialogHide={() => {
            console.log("onMessengerDialogHide");
          }}
          onMessengerMounted={() => {
            console.log("onMessengerMounted");
          }}
          onMessengerLoad={() => {
            console.log("onMessengerLoad");
          }}
        /> */}
        </Messenger>
      </Main>
      <Footer />
    </>
  );
}

import styled from "styled-components";
import Stars from "components/utils/Stars";
import { ReactComponent as BagIcon } from "../../assets/icons/bag.svg";
import { useNavigate } from "react-router-dom";
import { getImageUrl } from "lib/getRestApi";

const Product = styled.section`
  max-width: 17rem;
  .image {
    padding: 2rem;
    margin-bottom: 1.5rem;
    min-height: 220px;
    min-width: 100%;
    display: grid;
    place-items: center;
    border-radius: 0.5rem;
    object-fit: contain;
    img {
      width: 100%;
      aspect-ratio: 1 / 1;
      height: 100%;
      object-fit: contain;
    }
  }
  &:hover .image {
    border: solid 3px var(--primary-color);
  }
  .link {
    text-decoration: none;
  }
  h4 {
    color: #222222;
    font-weight: bold;
    font-size: var(--text-4);

    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .rating {
    margin: 0.65rem 0;
    font-size: var(--text-4);
  }

  footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .price {
      color: var(--primary-color);
      font-size: var(--text-4);
      font-weight: bold;
    }
    .bag-icon {
      margin-bottom: 0.15rem;
      border: none;
      background-color: transparent;
    }
  }
  @media screen and (min-width: 928px) {
    max-width: 25rem;
    h4 {
      font-size: var(--text-6);
    }
  }
`;

export default function ProductItem({ item }) {
  const options = JSON.parse(item?.product_options);
  const navigate = useNavigate();
  return (
    <Product onClick={() => navigate(`/products/${item?.id}`)}>
      <div className="image">
        <img src={getImageUrl(JSON.parse(item?.photo)[0])} alt="" />
      </div>
      <h4>{item?.name}</h4>
      <div className="rating">
        <Stars count={item?.rating} />
      </div>
      <footer>
        <div className="price">
          {options?.length > 0 && options[0]?.price} MMK
        </div>
        <button className="bag-icon" style={{ cursor: "pointer" }}>
          <BagIcon />
        </button>
      </footer>
    </Product>
  );
}

import React from "react";
import { MaxWidth } from "components/MaxWidth";
import styled from "styled-components";
import { ReactComponent as IconPhone } from "assets/icons/pphone.svg";
import IconEmail from "assets/icons/email.svg";
import { ReactComponent as IconLocation } from "assets/icons/location.svg";
import IconFacebook from "assets/icons/facebook.svg";
import IconInstagram from "assets/icons/instagram.svg";

import Logo from "assets/images/logo.svg";
import { Link } from "react-router-dom";

const Wrapper = styled(MaxWidth)`
  width: 90%;
  & > section {
    margin-bottom: 3rem;

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 4rem;
    }
  }

  @media screen and (min-width: 1200px) {
    display: flex;
    padding: 5rem 0;
    padding-top: 9rem;
    align-items: flex-start;

    & > section {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
`;

const Info = styled.section`
  display: flex;
  flex-direction: column;
  color: #ffffff;

  .logo {
    align-self: center;
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      object-fit: cover;
      /* border: 1px solid white; */
      width: 130%;
      height: 130%;
    }
  }
  p {
    font-size: var(--text-3);
    padding: 24px 0;
    line-height: 1.5;
    padding-right: 20px;
    text-align: center;
  }

  .offer {
    text-transform: uppercase;
    color: var(--primary-color);
    font-size: var(--text-6);
  }

  @media screen and (min-width: 1200px) {
    flex: 1.1;
    padding-right: 30px;
    p {
      font-size: var(--text-4);
      text-align: left;
    }
    .logo {
      align-self: flex-start;
    }
    .offer {
      font-size: var(--text-7);
    }
  }
`;

const Title = styled.h1`
  text-transform: uppercase;
  color: #ffffff;
  font-size: ${({ fontSize }) => fontSize};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "0rem")};
  letter-spacing: 1px;
  margin-bottom: ${({ mb }) => mb || "1.6rem"};
  @media screen and (min-width: 1200px) {
    margin-top: 0;
    margin-bottom: ${({ mb }) => mb || "4rem"};
  }
`;

const OfficeShowrooms = styled.section`
  flex: 1;
  li {
    margin-top: 1rem;
    color: #ffffff;
    font-size: var(--text-4);
    font-weight: normal;
    display: grid;
    grid-template-columns: 38px 1fr;
    align-items: center;
    .label {
      margin-bottom: 1rem;
    }
  }
  .socials {
    width: max-content;
    margin-top: 1.5rem;
    margin-bottom: 4rem;
    display: flex;
    gap: 2rem;
    a.insta {
      transform: scale(0.9);
    }
    a.fb {
      transform: scale(0.9);
    }
  }
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  @media screen and (min-width: 1200px) {
    .socials {
      padding-top: 2rem;
      gap: 1.75rem;
      a.insta {
        transform: scale(1);
      }
      a.fb {
        transform: scale(0.95);
      }
    }
  }
`;

const OpeningHours = styled.section`
  flex: 1;
  li {
    width: 100%;
    display: flex;
    gap: 4px;
    justify-content: space-between;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  @media screen and (min-width: 1200px) {
    margin-left: 2;
  }
`;

const Day = styled.div`
  font-size: var(--text-4);
  color: #ffffff;
  flex: 1;
  position: relative;

  span:last-child {
    background-color: #222222;
    display: inline-block;
    z-index: 2;
    position: relative;
    padding-right: 5%;
  }

  .dotted {
    flex: 1;
    width: 100%;
    height: 2px;
    display: block;
    border-top: 2px dotted var(--primary-color);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
  }
`;
const Hour = styled.div`
  width: 100%;
  max-width: 16rem;
  padding-left: 1rem;
  font-size: var(--text-4);
  color: ${({ close }) => (close ? "var(--primary-color)" : " #ffffff")};
  /* flex: 0; */
`;

const CoffeeShops = styled.section`
  @media screen and (min-width: 1200px) {
    padding-left: 50px;
    flex: 0.7;
  }
`;

const Shop = styled.div`
  padding-top: ${({ paddingTop }) => paddingTop || "0px"};
  padding-bottom: ${({ paddingBottom }) => paddingBottom || "0px"};
  border-bottom: ${({ borderBottom }) => borderBottom || "none"};

  li {
    color: #ffffff;
    font-size: var(--text-2);
    font-weight: normal;
    display: grid;
    grid-template-columns: 26px 1fr;
    grid-column-gap: 8px;
    &:not(:last-child) {
      margin-bottom: 6px;
    }

    .icon svg {
      width: 100%;
    }
  }
`;

export default function FooterInfo() {
  return (
    <Wrapper>
      <Info>
        <div className="logo">
          <img src={Logo} alt="Mountaineer Coffee logo" />
        </div>
        <p>
          Outside of the 9-5 life, John at Manual Coffee Brewing enjoys roasting
          coffee at home and sharing his experiments with his followers. Learn
          about different techniques of home brewing, including pour-over and
          immersion.
        </p>
        <Link to={"/contact?offer=true"} className="offer">
          JOB offers
        </Link>
      </Info>

      <OfficeShowrooms>
        <Title fontSize="var(--text-6)" marginTop={"-2rem"}>
          Office and showroom
        </Title>

        <ul>
          <li>
            <span className="icon">
              <IconPhone />
            </span>
            <a
              href="tel:+959882672086"
              style={{ textDecoration: "none" }}
              className="label"
            >
              (+95) 9 882 672 086
            </a>
          </li>
          <li>
            <img src={IconEmail} alt="email" style={{ width: "2.1rem" }} />
            <a
              style={{ textDecoration: "none" }}
              href="mailto:mountaineercoffee.mm@gmail.com"
              className="label"
            >
              mountaineercoffee.mm@gmail.com
            </a>
          </li>
          <li>
            <span className="icon">
              <IconLocation />
            </span>
            <div className="label">
              No.4B Say Yone Road, Yankin Township,Yangon
            </div>
          </li>
          <li className="socials">
            <a
              href="https://www.instagram.com/mountaineercoffee.mm/"
              target="_blank"
              rel="norefferer"
              className="insta"
            >
              <img src={IconInstagram} alt="Instagram Icon" />
            </a>
            <a
              href="https://www.facebook.com/Mountaineer-Coffee-Myanmar-867333956944843/"
              target="_blank"
              rel="norefferer"
              className="fb"
            >
              <img src={IconFacebook} alt="Facebook" />
            </a>
          </li>
        </ul>
      </OfficeShowrooms>

      <OpeningHours>
        <Title fontSize="var(--text-6)">Opening hours</Title>
        <ul>
          <li>
            <Day>
              <span className="dotted"></span>
              <span>Monday</span>
            </Day>
            <Hour>8:30 AM - 5:30 PM</Hour>
          </li>

          <li>
            <Day>
              <span className="dotted"></span>
              <span>Tuesday</span>
            </Day>
            <Hour>8:30 AM - 5:30 PM</Hour>
          </li>

          <li>
            <Day>
              <span className="dotted"></span>
              <span>Wednesday</span>
            </Day>
            <Hour>8:30 AM - 5:30 PM</Hour>
          </li>

          <li>
            <Day>
              <span className="dotted"></span>
              <span>Thursday</span>
            </Day>
            <Hour>8:30 AM - 5:30 PM</Hour>
          </li>

          <li>
            <Day>
              <span className="dotted"></span>
              <span>Friday</span>
            </Day>
            <Hour>8:30 AM - 5:30 PM</Hour>
          </li>

          <li>
            <Day>
              <span className="dotted"></span>
              <span>Saturday</span>
            </Day>
            <Hour>8:30 AM - 5:30 PM</Hour>
          </li>

          <li>
            <Day>
              <span className="dotted"></span>
              <span>Sunday</span>
            </Day>
            <Hour close={true}>Closed</Hour>
          </li>
        </ul>
      </OpeningHours>

      <CoffeeShops>
        <Title fontSize="var(--text-6)">Coffee shops</Title>
        <Shop paddingBottom="20px" borderBottom="1px solid #434343">
          <Title fontSize="var(--text-4)" mb="20px">
            Yangon coffee shop
          </Title>
          <ul>
            <li>
              <span className="icon">
                <IconPhone />
              </span>
              <div className="label">(+95) 9 894 280 007</div>
            </li>
            <li>
              <span className="icon">
                <IconLocation />
              </span>
              <div className="label">No. 42(C), Yawmingyi Street, Yangon</div>
            </li>
          </ul>
        </Shop>

        <Shop paddingTop="16px">
          <Title fontSize="var(--text-4)" mb="20px">
            Bangkok coffee shop
          </Title>
          <ul>
            <li>
              <span className="icon">
                <IconPhone />
              </span>
              <div className="label">(+66) 2 227 0941</div>
            </li>
            <li>
              <span className="icon">
                <IconLocation />
              </span>
              <div className="label">
                110 Sukhaphiban 2 Road, Dok Mai, Prawet, Bangkok
              </div>
            </li>
          </ul>
        </Shop>
      </CoffeeShops>
    </Wrapper>
  );
}

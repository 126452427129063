import styled from "styled-components";
import { motion } from "framer-motion";
import { useContext, useState, useEffect, useRef } from "react";
import { Context } from "lib/store";
import * as actions from "lib/store/actions";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";
import { gapi } from "gapi-script";
import axios from "axios";
import { getApiEndpoint } from "lib/getRestApi";
import { FillBtn } from "components/utils/Button";

import CloseIcon from "../../assets/icons/close.png";
import LoginImage from "../../assets/images/login.JPG";
import { ReactComponent as GoogleIcon } from "../../assets/icons/google.svg";
import { ReactComponent as FacebookIcon } from "../../assets/icons/facebook-color.svg";
import { ReactComponent as EyeIcon } from "../../assets/icons/eye.svg";

const Container = styled(motion.div)`
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 110;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10rem;
`;

const InnerContainer = styled(motion.div)`
  width: 100%;
  max-width: 95vw;
  background-color: white;
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 10rem;
  max-height: 90vh;
  @media screen and (min-width: 928px) {
    max-width: 60vw;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 6rem;
  }
`;

const Form = styled.form`
  position: relative;
  flex: 1;
  padding: 2.5rem 1.5rem;
  padding-bottom: 4rem;
  font-family: var(--family-h-medium);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  color: #222222;
  button {
    cursor: pointer;
  }
  .sign {
    text-align: center;
    width: max-content;
    margin-inline: auto;
    color: #676767;
    a {
      color: var(--primary-color);
      cursor: pointer;
    }
  }
  .facebook-btn {
    width: 100% !important;
    height: 4.25rem;
    border: solid 1px #707070;
    border-radius: 0.5rem;
    font-weight: bold;
    background-color: transparent;
    font-size: 16px;
  }
  .social-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    div {
      transform: scale(0.5);
    }
  }

  @media screen and (min-width: 928px) {
    padding: 2.5rem 6rem;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: 100%;
  position: relative;
  input {
    width: 100%;
    height: 4.25rem;
    border-radius: 0.5rem;
    border: none;
    background-color: #eeeeee;
    font-size: var(--text-4);
    padding-left: 1rem;
  }
  .forgot {
    margin-left: auto;
    width: max-content;
    color: #888888;
    cursor: pointer;
  }
  .eye-icon {
    position: absolute;
    right: 1rem;
    top: 52%;
    transform: translateY(-50%) scale(0.8);
  }
`;

const LoginBtn = styled(FillBtn)`
  font-size: var(--text-4);
  border-radius: 0.5rem;
  font-weight: bold;
`;

const CheckBox = styled.div`
  display: flex;
  color: #888888;
  align-items: center;
  margin-top: -1rem;
  gap: 1rem;
  input {
    transform: scale(1.25);
    margin-left: 0.25rem;
  }
  label {
    font-size: var(--text-4);
  }
`;

const Or = styled.div`
  color: #c9c9c9;
  display: flex;
  align-items: center;
  width: 95%;
  margin-inline: auto;
  gap: 1rem;
  div {
    flex: 1;
    width: 100%;
    max-width: 17rem;
    margin-inline: auto;
    height: 2px;
    background-color: #c9c9c9;
  }
  span {
    font-size: var(--text-3);
  }
`;

const MobileCloseBtn = styled.button`
  position: absolute;
  top: 1.5rem;
  right: 0;
  background-color: transparent;
  border: none;
  transform: scale(0.3);
  @media screen and (min-width: 928px) {
    display: none;
  }
`;

const Image = styled.div`
  flex: 1;
  object-fit: cover;
  display: none;
  max-height: 90vh;

  img {
    width: 100%;
    height: 100%;
  }
  @media screen and (min-width: 928px) {
    display: block;
  }
`;

const customLoginBtn = {
  width: "100%",
  height: " 4.25rem",
  border: "solid 1px #707070",
  borderRadius: "0.5rem",
  fontWeight: "bold",
  backgroundColor: "transparent",
  fontSize: "16px",
};

const StatusText = styled.span`
  color: red;
  font-weight: bold;
  text-align: center;
  width: 100%;
`;

function LoginForm() {
  const { dispatch } = useContext(Context);
  const [showPassword, setShowPassword] = useState(false);
  const [loginStatus, setLoginStatus] = useState({ success: null, msg: "" });
  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    const start = () => {
      gapi.client.init({
        clientId: process.env.REACT_APP_CLIENT_ID,
        scope: "",
      });
    };
    gapi.load("client:auth2", start);
  }, []);

  const AuthRef = useRef();
  const PasswordRef = useRef();

  const closeModal = () => dispatch({ type: actions.CLOSE_LOGIN });
  const signUp = () =>
    dispatch({ type: actions.CHANGE_STAGE, payload: { state: "signup" } });

  const handleLogin = async (e) => {
    e.preventDefault();
    if (AuthRef.current.value === "" || PasswordRef.current.value === "") {
      setLoginStatus({
        success: false,
      });
      return;
    }
    try {
      const { data } = await axios.post(getApiEndpoint("login"), {
        email: AuthRef.current.value,
        password: PasswordRef.current.value,
      });
      if (data?.message === "success") {
        setLoginStatus({ success: true, msg: "success" });
        dispatch({
          type: actions.LOGGED_IN,
          payload: {
            token: data.data.atoken,
            user: { name: data.data.name, email: data.data.email },
            rememberMe: rememberMe,
          },
        });
        dispatch({ type: actions.CLOSE_LOGIN });
      } else {
        setLoginStatus({
          success: false,
          msg: "Email or Password is not correct",
        });
      }
    } catch (error) {
      setLoginStatus({
        success: false,
        msg: "Something went wrong",
      });
    }
  };

  const handleSuccess = async (result) => {
    const userData = {
      name: result?.profileObj?.name,
      email: result?.profileObj?.email,
      idToken: result?.tokenId,
      social: "google",
    };
    try {
      const { data } = await axios.post(
        getApiEndpoint("social-login"),
        userData
      );
      if (data?.message === "success") {
        dispatch({
          type: actions.LOGGED_IN,
          payload: {
            token: data.data.atoken,
            user: { name: data.data.name, email: data.data.email },
            rememberMe: rememberMe,
          },
        });
        dispatch({ type: actions.CLOSE_LOGIN });
      }
    } catch (error) {}
  };

  const handleFailure = (err) => console.log(err);

  const responseFacebook = async (response) => {
    const userData = {
      name: response?.name,
      email: response?.email,
      idToken: response?.accessToken,
      social: "facebook",
    };
    try {
      const { data } = await axios.post(
        getApiEndpoint("social-login"),
        userData
      );
      if (data?.message === "success") {
        dispatch({
          type: actions.LOGGED_IN,
          payload: {
            token: data.data.atoken,
            user: { name: data.data.name, email: data.data.email },
            rememberMe: rememberMe,
          },
        });
        dispatch({ type: actions.CLOSE_LOGIN });
      }
    } catch (error) {}
  };

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      onClick={closeModal}
    >
      <InnerContainer
        onClick={(e) => e.stopPropagation()}
        initial={{ y: "120%" }}
        animate={{ y: 0, transition: { duration: 0.5, delay: 0.25 } }}
      >
        <Form>
          <h2>Login</h2>
          <p>Please login here if you already have an account.</p>
          <InputContainer>
            <label htmlFor="email">Email</label>
            <input
              type="text"
              name="email"
              ref={AuthRef}
              style={{
                border: loginStatus.success === false ? "solid 1px red" : "",
              }}
            />
          </InputContainer>
          <InputContainer>
            <label htmlFor="Password">Password</label>
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              ref={PasswordRef}
              style={{
                border: loginStatus.success === false ? "solid 1px red" : "",
              }}
            />
            <div className="eye-icon">
              <EyeIcon onClick={() => setShowPassword(!showPassword)} />
            </div>
            <span
              className="forgot"
              onClick={() =>
                dispatch({
                  type: actions.CHANGE_STAGE,
                  payload: { state: "forgot" },
                })
              }
            >
              Forgot password?
            </span>
          </InputContainer>
          <CheckBox>
            <input
              type="checkbox"
              onChange={(e) => setRememberMe(e.target.checked)}
            />
            <label htmlFor="remember">Remember me</label>
          </CheckBox>
          {loginStatus.success === false && (
            <StatusText>{loginStatus.msg}</StatusText>
          )}
          <LoginBtn onClick={handleLogin}>LOGIN</LoginBtn>
          <p className="sign">
            Don't you have any account? <a onClick={signUp}>Sign up</a>
          </p>
          <Or>
            <div></div>
            <span>OR</span>
            <div></div>
          </Or>
          <GoogleLogin
            clientId={process.env.REACT_APP_CLIENT_ID}
            cookiePolicy={"single_host_origin"}
            onSuccess={handleSuccess}
            onFailure={handleFailure}
            buttonText="Sign in with Google"
            render={(renderProps) => (
              <button
                onClick={renderProps.onClick}
                style={customLoginBtn}
                className="social-btn"
              >
                <div>
                  <GoogleIcon />
                </div>
                Sign in with Google
              </button>
            )}
          ></GoogleLogin>
          <FacebookLogin
            // appId="1168442733704584"
            appId="8186197481422040"
            autoLoad={false}
            fields="name,email"
            callback={responseFacebook}
            cssClass="facebook-btn social-btn"
            containerStyle={{ width: "100%" }}
            textButton="Sign in with Facebook"
            icon={
              <div>
                <FacebookIcon />
              </div>
            }
          ></FacebookLogin>
          <MobileCloseBtn onClick={closeModal}>
            <img src={CloseIcon} alt="close icon" />
          </MobileCloseBtn>
        </Form>
        <Image>
          <img src={LoginImage} alt="" />
        </Image>
      </InnerContainer>
    </Container>
  );
}

export default LoginForm;

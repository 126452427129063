import { Link } from "react-router-dom";
import { MaxWidth } from "components/MaxWidth";
import styled from "styled-components";
import AboutUsImage from "assets/images/About_us_home.png";
import IconCoffeeCup from "assets/icons/about-us.svg";
import { Button } from "components/utils/Button";

const Section = styled.section`
  background-color: #f3f1ed;
  padding-top: 6rem;
  padding-bottom: 5rem;

  @media screen and (min-width: 992px) {
    padding-top: 14rem;
    padding-bottom: 8rem;
  }
`;
const Wrapper = styled(MaxWidth)`
  & > * {
    flex: 1;
  }

  .left {
    .img-wrappper {
      position: relative;
      background-color: rgba(var(--primary-color-rgb), 0.1);
      top: 0;
      img {
        object-fit: cover;
        max-width: 100%;
        height: 100%;

        @media screen and (min-width: 992px) {
          position: relative;
          top: -20px;
          left: -20px;
        }
      }
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1.4rem;
    header {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      .icon-coffee {
        width: 20px;
        aspect-ratio: 1 / 1;
        object-fit: contain;
        margin-top: 6px;
        img {
          width: 100%;
        }
      }
      h3 {
        color: #707070;
        font-size: var(--text-5);
        margin-top: 1rem;
        font-weight: normal;
        text-transform: capitalize;
        font-family: var(--family-andale);
      }

      @media screen and (min-width: 992px) {
        justify-content: flex-start;
      }
    }

    .about {
      margin-top: 2rem;
      margin-bottom: 2.5rem;
      p {
        text-align: center;
        font-size: var(--text-5);
        line-height: 1.5;
        font-family: var(--family-h-light);

        &:not(:last-child) {
          margin-bottom: 2rem;
        }
      }
    }
    @media screen and (min-width: 992px) {
      margin-top: 0;
      justify-content: flex-start;
      align-items: flex-start;
      .about {
        p {
          text-align: left;
        }
      }
    }
  }

  @media screen and (min-width: 992px) {
    display: flex;
    gap: 5rem;
  }
`;

export default function About() {
  return (
    <Section>
      <Wrapper>
        <div className="left">
          <div className="img-wrappper">
            <img src={AboutUsImage} alt="Mountaineer Coffee" />
          </div>
        </div>
        <article className="right">
          <header>
            <div className="icon-coffee">
              <img src={IconCoffeeCup} alt="coffee cup icon" />
            </div>
            <h3>About us</h3>
          </header>
          <div className="about">
            <p>
              Since 2011, from the heights of the mountains in Northern Shan
              State, Arabica coffee beans are genuinely farmed with passion by
              mountain people. What started as a family business has been fully
              transformed into a coffee company through Mountaineer’s enthusiasm
              for coffee. Our mission has always remained the same, which is to
              be committed and be the main driver of growth in Myanmar’s
              specialty coffee industry. From our experience in farming,
              processing, roasting and brewing, we exist to serve with
              excellence through every cup of coffee.
            </p>
          </div>
          <Link to={"/about"} className="btn-container">
            <Button bgColor="#DFB368">Explore More</Button>
          </Link>
        </article>
      </Wrapper>
    </Section>
  );
}

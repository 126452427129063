import styled from 'styled-components';

const Badge = styled.div`
  padding: 0.3rem 1.8rem;
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
  background-color: #5f9b42;
  border-radius: 6px;
  color: #ffffff;
  font-size: var(--text-2);
  @media screen and (min-width: 928px) {
    font-size: var(--text-3);
    top: 1.2rem;
    left: 1.2rem;
  }
`;

export default Badge;

import React, { lazy, Suspense, useContext, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import Layout from "./layout";

import Home from "routes";
import FallbackLoading from "components/utils/FallBackLoading";
import * as actions from "lib/store/actions";
import { Context } from "lib/store";
import { getDataWithToken } from "lib/getRestApi";

const AboutPreloaded = import("routes/about");
const MenuPreloaded = import("routes/menu");
const BlogsPreloaded = import("routes/blogs");
const BlogDetailsPreloaded = import("routes/blog-detail");
const GalleryPreloaded = import("routes/gallery");
const ProductsPreloaded = import("routes/products");
const ProductDetailPreloaded = import("routes/product-detail");
const CartPreloaded = import("routes/cart");
const ReceipesPreloaded = import("routes/recipes");
const ReceipeDetailPreloaded = import("routes/recipes-details");
const ContactPreloaded = import("routes/contact");
const OrderListPreloaded = import("routes/order-list");
const OrderDetailPreloaded = import("routes/order-detail");
const ProfilePreloaded = import("routes/profile");
const NotFoundPreloaded = import("routes/notfound");

const About = lazy(() => AboutPreloaded);
const Menu = lazy(() => MenuPreloaded);
const Blogs = lazy(() => BlogsPreloaded);
const BlogDetails = lazy(() => BlogDetailsPreloaded);
const Gallery = lazy(() => GalleryPreloaded);
const Products = lazy(() => ProductsPreloaded);
const ProductDetail = lazy(() => ProductDetailPreloaded);
const Cart = lazy(() => CartPreloaded);
const Receipes = lazy(() => ReceipesPreloaded);
const ReceipeDetail = lazy(() => ReceipeDetailPreloaded);
const Contact = lazy(() => ContactPreloaded);
const OrderList = lazy(() => OrderListPreloaded);
const OrderDetail = lazy(() => OrderDetailPreloaded);
const Profile = lazy(() => ProfilePreloaded);
const NotFound = lazy(() => NotFoundPreloaded);

const App = () => {
  const { dispatch } = useContext(Context);
  useEffect(() => {
    let token = localStorage.getItem("token");
    let user = localStorage.getItem("user");
    if (token && user) {
      token = JSON.parse(token);
      user = JSON.parse(
        JSON.parse(JSON.stringify(localStorage.getItem("user")))
      );
      dispatch({
        type: actions.LOGGED_IN,
        payload: {
          token: token,
          user: {
            name: user.name,
            emial: user.email,
          },
        },
      });
      getDataWithToken("carts", token).then((res) => {
        dispatch({
          type: actions.SET_CART_QTY,
          payload: { length: res?.data?.results?.length },
        });
      });
    }
  }, []);
  return (
    <Router>
      <Layout>
        <AnimatePresence exitBeforeEnter>
          <Suspense fallback={<FallbackLoading />}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/menu" element={<Menu />} />
              <Route path="/blogs" element={<Blogs />} />
              <Route path="/blogs/:blogId" element={<BlogDetails />} />
              <Route path="/gallery" element={<Gallery />} />
              <Route path="/products" element={<Products />} />
              <Route path="/products/:productId" element={<ProductDetail />} />
              <Route path="/cart" element={<Cart />} />
              <Route path="/recipes" element={<Receipes />} />
              <Route path="/recipes/:recipesId" element={<ReceipeDetail />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/order-list" element={<OrderList />} />
              <Route path="/order-list/:orderId" element={<OrderDetail />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
        </AnimatePresence>
      </Layout>
    </Router>
  );
};

export default App;

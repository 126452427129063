import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { MaxWidth } from "components/MaxWidth";
import SectionInfo from "components/utils/SectionInfo";
import MenuItem from "components/food-menu/MenuItem";
import { getData } from "lib/getRestApi";
import Chevron from "../../assets/icons/chevron.png";
import { Button } from "components/utils/Button";
import { useNavigate } from "react-router-dom";

const Section = styled.section`
  background-color: #222222;
  border-bottom: 1px solid white;
  padding-top: 3rem;
  padding-bottom: 4rem;
  @media screen and (min-width: 922px) {
    padding-block: 6rem;
  }
`;

const Wrapper = styled(MaxWidth)`
  width: 90%;
  .chevron {
    border: none;
    background-color: transparent;
    height: max-content;
    width: 100%;
    cursor: pointer;
  }
  .up {
    margin-top: 1rem;
    transform: rotate(180deg);
  }
  .down {
    margin-top: 2rem;
  }
  .view-all {
    margin-top: 3rem;
    width: max-content;
    margin-inline: auto;
  }
  @media screen and (min-width: 768px) {
    .chevron {
      display: none;
    }
  }
`;

const Menus = styled.div`
  display: flex;
  flex-direction: column;
  height: 27rem;
  margin-top: 2rem;
  overflow: hidden;
  font-family: var(--family-h-light);
  &::-webkit-scrollbar {
    display: none;
  }
  @media screen and (min-width: 786px) {
    height: 40rem;
    padding-left: 3rem;
  }
  @media screen and (min-width: 992px) {
    padding-left: 5rem;
  }
`;

const MenuContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-column-gap: 2rem;
  overflow-x: visible;
  grid-row-gap: 2rem;
  display: none;
  height: max-content;
  @media screen and (min-width: 786px) {
    display: grid;
    grid-column-gap: 7rem;
  }
`;

const MobileMenuContainer = styled.div`
  height: max-content;
  .menus-container {
    height: 27rem;
    overflow-y: hidden;
    overflow-x: visible;
    .menus {
      display: grid;
      grid-template-columns: 1fr;
      height: 100%;
      gap: 3rem;
      padding-left: 1.25em;
      transition: transform 300ms ease;
      padding-top: 1rem;
      & > *:nth-child(4n + 5) {
        margin-top: 2.25rem;
      }
      @media screen and (min-width: 786px) {
        display: none;
      }
    }
  }
`;

export default function PopularMenus() {
  const [menu, setMenus] = useState([]);
  const [clickable, setClickable] = useState({ up: 0, down: 0 });
  const [currentY, setCurrentY] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    getData("foodmenu?limit=8")
      .then((res) => {
        setMenus(res?.data?.results);
        setClickable({
          up: 0,
          down: Math.ceil(res?.data?.results?.length / 4),
        });
      })
      .catch((err) => console.log("err => ", err));
  }, []);
  const MenuRef = useRef();
  const upDown = (dir) => {
    if (dir === "up" && clickable.up > 0) {
      MenuRef.current.style.transform = `translateY(${currentY + 368}px)`;
      setCurrentY(currentY + 368);
      setClickable({ down: clickable.down + 1, up: clickable.up - 1 });
    }
    if (dir === "down" && clickable.down > 1) {
      MenuRef.current.style.transform = `translateY(${currentY - 368}px)`;
      setCurrentY(currentY - 368);
      setClickable({ down: clickable.down - 1, up: clickable.up + 1 });
    }
  };
  return (
    <Section>
      <Wrapper>
        <SectionInfo title="Mountaineer Popular Menu" titleColor="white" />
        {menu?.length ? (
          <>
            <button className="chevron up" onClick={() => upDown("up")}>
              <img src={Chevron} alt="chevron up" />
            </button>
            <Menus>
              <MenuContainer>
                {menu?.map((item, index) => (
                  <div key={index}>
                    <MenuItem key={item?.id} item={item} />
                  </div>
                ))}
              </MenuContainer>
              <MobileMenuContainer>
                <div className="menus-container">
                  <div className="menus" ref={MenuRef}>
                    {menu?.length &&
                      menu.map((item) => (
                        <MenuItem key={item?.id} item={item} />
                      ))}
                  </div>
                </div>
              </MobileMenuContainer>
            </Menus>
            <button className="chevron down" onClick={() => upDown("down")}>
              <img src={Chevron} alt="chevron down" />
            </button>
          </>
        ) : (
          <div style={{ height: "20vh" }}></div>
        )}
        <div className="view-all">
          <Button bgColor="#DFB368" onClick={() => navigate("/menu")}>
            View all
          </Button>
        </div>
      </Wrapper>
    </Section>
  );
}
{
}

import axios from "axios";

export async function getData(endpoint) {
  const apiUrl = process.env.REACT_APP_API_BASEURL;
  try {
    const { data } = await axios.get(apiUrl + endpoint);
    if (data) return data;
    if (!data) return [];
  } catch (error) {
    return error;
  }
}

export function getImageUrl(name) {
  return process.env.REACT_APP_IMAGE_URL + name;
}

export function getApiEndpoint(endpoint) {
  return process.env.REACT_APP_API_BASEURL + endpoint;
}

export async function getDataWithToken(endpoint, token) {
  const apiUrl = process.env.REACT_APP_API_BASEURL;
  try {
    const { data } = await axios.get(apiUrl + endpoint, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (data) return data;
    if (!data) return [];
  } catch (error) {
    throw new Error(error);
  }
}

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";

import SectionInfo from "components/utils/SectionInfo";
import { MaxWidth } from "components/MaxWidth";
import { ButtonReadMore, Button } from "components/utils/Button";
import { ReactComponent as BlogProfileIcon } from "assets/icons/blog-profile.svg";
import { ReactComponent as CalenderIcon } from "assets/icons/calender.svg";
import { getData, getImageUrl } from "lib/getRestApi";
import axios from "axios";
import moment from "moment";

const Section = styled.section`
  padding-top: 3rem;
  padding-bottom: 4rem;

  @media screen and (min-width: 1200px) {
    padding: 6rem 0;
  }
`;

const Wrapper = styled(MaxWidth)`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Blogs = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-bottom: 2rem;
  width: 100%;
  @media screen and (min-width: 992px) {
    margin-top: 7rem;
    margin-bottom: 5rem;
    flex-direction: row;
    & > * {
      flex: 1;
    }
  }
`;

const Blog = styled.div`
  border-radius: 6px;
  padding: 1.5rem;
  box-shadow: 0 0 3px #00000029;
  width: 100%;
  max-width: 45rem;
  .image {
    height: 240px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 6px;
    }
  }

  h3 {
    color: #222222;
    margin-top: 2.1rem;
    margin-bottom: 1.1rem;
    font-size: var(--text-5);
  }

  p {
    color: #7c7c7c;
    font-size: var(--text-4);
    line-height: 1.6;
    margin-bottom: 1rem;

    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

const Actions = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  border-top: 1px solid #eeeeee;
  padding-top: 1.6rem;
  margin-top: 1.6rem;

  .socials {
    display: flex;
    gap: 3rem;
    align-items: center;
  }
`;

const Action = styled.div`
  display: flex;
  gap: 0.8rem;
  align-items: center;

  span {
    font-size: var(--text-2);
  }
`;

export default function NewsBlogs() {
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    getData("blog")
      .then((res) => setBlogs(res?.data?.results))
      .catch((err) => console.log(err));
  }, []);
  return (
    <Section>
      <Wrapper>
        <SectionInfo title="Blogs & Recipes" />
        <Blogs>
          {blogs &&
            blogs?.slice(0, 3)?.map((item) => (
              <Blog key={item.id}>
                <Thumbnail item={item} />
                <h3>{item.title}</h3>
                <p>{item.description}</p>
                <Link
                  to={
                    item?.category_name === "Recipe"
                      ? `/recipes/${item.id}`
                      : `/blogs/${item.id}`
                  }
                >
                  <ButtonReadMore>Read more &raquo;</ButtonReadMore>
                </Link>
                <Actions>
                  <Action>
                    <BlogProfileIcon />
                    <span>Mountaineer Coffee</span>
                  </Action>

                  <div className="socials">
                    <Action>
                      <CalenderIcon />
                      <span>
                        {moment
                          .unix(item?.created_at / 1000)
                          .format("DD-MM-YYYY")}
                      </span>
                    </Action>
                  </div>
                </Actions>
              </Blog>
            ))}
        </Blogs>
        <Button bgColor="#DFB368" onClick={() => navigate("/blogs")}>
          View all
        </Button>
      </Wrapper>
    </Section>
  );
}

const Thumbnail = ({ item }) => {
  const [thumbnail, setThumbnail] = useState("");
  useEffect(() => {
    if (item?.youtube_link) {
      const getThumbnail = async () => {
        const { data } = await axios.get(
          `http://www.youtube.com/oembed?url=${item?.youtube_link}&format=json`
        );
        setThumbnail(data?.thumbnail_url);
      };
      getThumbnail();
    }
    if (item?.photo) {
      setThumbnail(getImageUrl(item?.photo));
    }
  }, [item]);
  return (
    <div className="image">
      <img src={thumbnail} alt={item?.title} />
    </div>
  );
};

import styled from "styled-components";

export const Button = styled.button`
  border-radius: 6px;
  font-size: var(--text-5);
  background-color: ${({ bgColor }) => bgColor || "var(--primary-color)"};
  color: #fff;
  text-transform: capitalize;
  border: 1px solid var(--primary-color);
  padding: 1.6rem 2.4rem;
  margin: 1rem;
  cursor: pointer;
  position: relative;
  z-index: 1;
  overflow: hidden;
  &::after {
    content: "";
    background-color: var(--primary-color);
    position: absolute;
    z-index: -1;
    padding: 1.6rem 2.4rem;
    display: block;
    transition: all 0.35s;
    top: 0;
    bottom: 0;
    left: 100%;
    right: -100%;
  }
  &:hover::after {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: all 0.35s;
  }
`;

export const FillBtn = styled.button`
  font-size: var(--text-3);
  background-color: #d59c3be6;
  color: #fff;
  border: 1px solid #d59c3be6;
  height: 4rem;
  width: 100%;
  cursor: pointer;
  position: relative;
  z-index: 1;
  overflow: hidden;
  text-align: center;
  &::after {
    content: "";
    background-color: #d59c3b;
    position: absolute;
    z-index: -1;
    height: 4rem;
    width: 100%;
    display: block;
    transition: all 0.35s;
    top: 0;
    bottom: 0;
    left: 100%;
    right: -100%;
  }
  &:hover::after {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: all 0.35s;
  }
`;

export const ButtonReadMore = styled.button`
  border: none;
  outline: none;
  color: var(--primary-color);
  background: none;
  text-transform: capitalize;
  font-size: var(--text-3);
  font-weight: normal;
  cursor: pointer;
`;

export const ApplyButton = styled(FillBtn)`
  min-width: 140px;
  max-width: 160px;
`;

export const CheckOutButton = styled(FillBtn)`
  width: 100%;
`;

export const SaveChangesButton = styled(CheckOutButton)``;

export const CancelButton = styled(ApplyButton)`
  background-color: transparent;
  border: 1.5px solid #000000;
  color: #222222;
`;

import { useRef } from "react";
import styled from "styled-components";

import MountainImage from "assets/images/mountain.jpg";
import BannerMobile from "assets/images/mobile-banner.jpg";

import leftHand from "../../assets/images/left.png";
import rightHand from "../../assets/images/right.png";

const Section = styled.section`
  height: 280px;
  background-image: url(${BannerMobile});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  @media screen and (min-width: 768px) {
    background-image: url(${MountainImage});
    height: 380px;
  }

  @media screen and (min-width: 992px) {
    background-position: center bottom;
    height: 480px;
  }

  @media screen and (min-width: 1200px) {
    height: calc(100vh - var(--header-height));
  }
`;

const Hands = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  display: none;
  .hands {
    position: absolute;
    bottom: -2vh;
    height: 100%;
    width: 50%;
    img {
      width: 110vw;
    }
  }

  .left {
    left: 0;
  }

  .right {
    left: -10%;
  }

  @media screen and (min-width: 768px) {
    .hands {
      position: absolute;
      bottom: 9%;
      height: 100%;
      width: 50%;
      img {
        /* width: 170rem; */
        width: 110vw;
      }
    }
    .left {
      left: -2%;
    }

    .right {
      left: -10%;
    }
  }

  @media screen and (min-width: 928px) {
    display: block;
    .hands {
      position: absolute;
      bottom: 10%;
      height: 100%;
      width: 50%;
      img {
        /* width: 170rem; */
        width: 110vw;
      }
    }
    .left {
      left: -2%;
    }

    .right {
      left: -10%;
    }
  }
`;

export default function HomeBanner() {
  const sectionRef = useRef();
  const leftRef = useRef(null);
  const rightRef = useRef(null);

  const handleMouseMove = ({ pageX, pageY }) => {
    const cstd = 20; /** constraint move */

    const mx = (cstd / sectionRef.current.offsetWidth) * pageX * 5;
    // const my = (cstd / sectionRef.current.offsetHeight) * pageY * 5;

    leftRef.current.style.transform = `translateX(-${mx}px)`;
    rightRef.current.style.transform = `translateX(${mx}px)`;
    // leftRef.current.style.transform = `translate(-${mx}px, -${my}px)`;
    // rightRef.current.style.transform = `translate(${mx}px, ${my}px)`;
  };

  return (
    <Section ref={sectionRef} onMouseMove={handleMouseMove}>
      <Hands>
        <div ref={leftRef} className="hands left">
          <img src={leftHand} alt="left hand" />
        </div>
        <div ref={rightRef} className="hands right">
          <img src={rightHand} alt="right hand" />
        </div>
      </Hands>
    </Section>
  );
}

import { createContext, useReducer } from "react";
import * as actions from "./actions";

export const Context = createContext();

const initialState = {
  device: "sm",
  loginState: "login",
  showLoginModal: false,
  user: {
    name: null,
    email: null,
  },
  token: null,
  cartLength: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.SET_DEVICE:
      return { ...state, device: action?.payload };
    case actions.LOGGIN_IN:
      return { ...state, showLoginModal: true, loginState: "login" };
    case actions.CLOSE_LOGIN:
      return { ...state, showLoginModal: false, loginState: "login" };
    case actions.CHANGE_STAGE:
      return {
        ...state,
        loginState: action.payload.state,
        otp_token: action.payload.otp_token,
      };
    case actions.SET_CART_QTY:
      return { ...state, cartLength: action.payload.length };
    case actions.REMOVE_FROM_CART:
      return { ...state, cartLength: state.cartLength - 1 };
    case actions.LOGGED_IN:
      if (action.payload.rememberMe) {
        localStorage.setItem("token", JSON.stringify(action?.payload?.token));
        localStorage.setItem(
          "user",
          JSON.stringify({
            name: action?.payload.user.name,
            email: action?.payload.user.email,
          })
        );
      }
      return {
        ...state,
        user: action?.payload?.user,
        token: action?.payload.token,
      };

    default:
      return state;
  }
};

export default function StoreProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  );
}

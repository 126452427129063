import styled from "styled-components";
import { motion } from "framer-motion";
import { useContext, useRef, useState } from "react";
import { Context } from "lib/store";
import * as actions from "lib/store/actions";
import { ReactComponent as EyeIcon } from "../../assets/icons/eye.svg";
import CloseIcon from "../../assets/icons/close.png";
import axios from "axios";
import { getApiEndpoint } from "lib/getRestApi";
import { FillBtn } from "components/utils/Button";

import LoginImage from "../../assets/images/login.JPG";

const Container = styled(motion.div)`
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 110;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10rem;
`;

const InnerContainer = styled(motion.div)`
  width: 100%;
  max-width: 95vw;
  background-color: white;
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 10rem;
  max-height: 90vh;
  @media screen and (min-width: 928px) {
    max-width: 60vw;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 6rem;
  }
`;

const Form = styled.form`
  flex: 1;
  padding: 3rem 1.5rem;
  padding-bottom: 4rem;
  font-family: var(--family-h-medium);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  color: #222222;
  button {
    cursor: pointer;
  }
  .sign {
    text-align: center;
    width: max-content;
    margin-inline: auto;
    color: #676767;
    a {
      color: var(--primary-color);
      cursor: pointer;
    }
  }
  @media screen and (min-width: 928px) {
    padding: 2.5rem 6rem;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: 100%;
  position: relative;
  input {
    width: 100%;
    height: 4.25rem;
    border-radius: 0.5rem;
    border: none;
    background-color: #eeeeee;
    font-size: var(--text-4);
    padding-left: 1rem;
  }
  .forgot {
    margin-left: auto;
    width: max-content;
    color: #888888;
    cursor: pointer;
  }
  .eye-icon {
    position: absolute;
    right: 1rem;
    top: 73%;
    transform: translateY(-50%) scale(0.8);
  }
`;

const LoginBtn = styled(FillBtn)`
  font-size: var(--text-4);
  border-radius: 0.5rem;
  font-weight: bold;
`;
const MobileCloseBtn = styled.button`
  position: absolute;
  top: 1.5rem;
  right: 0;
  background-color: transparent;
  border: none;
  transform: scale(0.3);
  @media screen and (min-width: 928px) {
    display: none;
  }
`;

const Image = styled.div`
  flex: 1;
  object-fit: cover;
  display: none;
  max-height: 90vh;

  img {
    width: 100%;
    height: 100%;
  }
  @media screen and (min-width: 928px) {
    display: block;
  }
`;

const Status = styled.p`
  position: absolute;
  left: 50%;
  top: 50%;
  padding: 1rem;
  background-color: white;
  border: solid 2px springgreen;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

const StatusFailure = styled(Status)`
  border: solid 2px tomato;
`;

function SignUpForm() {
  const NameRef = useRef();
  const EmailRef = useRef();
  const PasswordRef = useRef();
  const PhoneRef = useRef();
  const [status, setStatus] = useState({ success: null, reason: "" });
  const { dispatch } = useContext(Context);
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });

  const closeModal = () => dispatch({ type: actions.CLOSE_LOGIN });
  const logIn = () =>
    dispatch({ type: actions.CHANGE_STAGE, payload: { state: "login" } });

  const signUpHandler = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(getApiEndpoint("signup"), {
        name: NameRef.current.value,
        email: EmailRef.current.value,
        phone: PhoneRef.current.value,
        password: PasswordRef.current.value,
      });
      if (res.data?.data === "Email is already") {
        setStatus({ success: false, reason: "Email already in used" });
        setTimeout(() => {
          setStatus({ success: null, reason: "" });
        }, 2000);
      }
      if (res.data?.data === "Succesfully Signup.") {
        setStatus({ success: true });
        setTimeout(() => {
          setStatus({ success: null });
          dispatch({ type: actions.CHANGE_STAGE, payload: { state: "login" } });
        }, 2000);
      }
    } catch (error) {}
  };
  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      onClick={closeModal}
    >
      <InnerContainer
        onClick={(e) => e.stopPropagation()}
        initial={{ y: "120%" }}
        animate={{ y: 0, transition: { duration: 0.5, delay: 0.25 } }}
      >
        <Form onSubmit={signUpHandler}>
          {status.success === true && <Status>Successfully Registered</Status>}
          {status.success === false && (
            <StatusFailure>{status.reason}</StatusFailure>
          )}
          <h2>Sign Up</h2>
          <p>Please register an account here if you don't have an account</p>
          <InputContainer>
            <label htmlFor="name">Name</label>
            <input type="text" name="name" ref={NameRef} />
          </InputContainer>
          <InputContainer>
            <label htmlFor="email">Email</label>
            <input type="text" name="email" ref={EmailRef} />
          </InputContainer>
          <InputContainer>
            <label htmlFor="phone">Phone Number</label>
            <input type="text" name="phone" ref={PhoneRef} />
          </InputContainer>
          <InputContainer>
            <label htmlFor="Password">Password</label>
            <input
              type={showPassword.password ? "text" : "password"}
              name="password"
              ref={PasswordRef}
            />
            <div className="eye-icon">
              <EyeIcon
                onClick={() =>
                  setShowPassword({
                    ...showPassword,
                    password: !showPassword.password,
                  })
                }
              />
            </div>
          </InputContainer>
          {/* <InputContainer>
            <label htmlFor="Password">Confirm Password</label>
            <input
              type={showPassword.confirmPassword ? "text" : "password"}
              name="password"
            />
            <div className="eye-icon">
              <EyeIcon
                onClick={() =>
                  setShowPassword({
                    ...showPassword,
                    confirmPassword: !showPassword.confirmPassword,
                  })
                }
              />
            </div>
          </InputContainer> */}
          {/* <CheckBox>
            <input type="checkbox" name="remember" value="remember" />
            <label htmlFor="remember">I agree terms & conditions</label>
          </CheckBox> */}
          <LoginBtn>Sign Up</LoginBtn>
          <p className="sign">
            Do you already have an account? <a onClick={logIn}>Login Here</a>
          </p>
          <MobileCloseBtn onClick={closeModal}>
            <img src={CloseIcon} alt="close icon" />
          </MobileCloseBtn>
        </Form>
        <Image>
          <img src={LoginImage} alt="" />
        </Image>
      </InnerContainer>
    </Container>
  );
}

export default SignUpForm;

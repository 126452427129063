import styled from "styled-components";
import { motion } from "framer-motion";
import { useContext, useRef, useState } from "react";
import { Context } from "lib/store";
import * as actions from "lib/store/actions";
import { FillBtn } from "components/utils/Button";
import { getApiEndpoint } from "lib/getRestApi";
import axios from "axios";

import CloseIcon from "../../assets/icons/close.png";
import LoginImage from "../../assets/images/login.JPG";

const Container = styled(motion.div)`
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 110;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10rem;
`;

const InnerContainer = styled(motion.div)`
  width: 100%;
  max-width: 95vw;
  background-color: white;
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 10rem;
  min-height: 70vh;
  max-height: 90vh;

  @media screen and (min-width: 928px) {
    max-width: 60vw;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 6rem;
  }
`;

const Form = styled.form`
  position: relative;
  flex: 1;
  padding: 2.5rem 1.5rem;
  padding-bottom: 4rem;
  font-family: var(--family-h-medium);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1.5rem;
  color: #222222;
  button {
    cursor: pointer;
  }
  .sign {
    text-align: center;
    width: max-content;
    margin-inline: auto;
    color: #676767;
    a {
      color: var(--primary-color);
      cursor: pointer;
    }
  }
  .facebook-btn {
    width: 100% !important;
    height: 4.25rem;
    border: solid 1px #707070;
    border-radius: 0.5rem;
    font-weight: bold;
    background-color: transparent;
    font-size: 16px;
  }

  @media screen and (min-width: 928px) {
    padding: 2.5rem 6rem;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: 100%;
  position: relative;
  input {
    width: 100%;
    height: 4.25rem;
    border-radius: 0.5rem;
    border: none;
    background-color: #eeeeee;
    font-size: var(--text-4);
    padding-left: 1rem;
    outline: none;
  }
  .forgot {
    margin-left: auto;
    width: max-content;
    color: #888888;
    cursor: pointer;
  }
  .eye-icon {
    position: absolute;
    right: 1rem;
    top: 52%;
    transform: translateY(-50%) scale(0.8);
  }
`;

const MobileCloseBtn = styled.button`
  position: absolute;
  top: 1.5rem;
  right: 0;
  background-color: transparent;
  border: none;
  transform: scale(0.3);
  @media screen and (min-width: 928px) {
    display: none;
  }
`;

const Image = styled.div`
  flex: 1;
  object-fit: cover;
  display: none;
  max-height: 90vh;

  img {
    width: 100%;
    height: 100%;
  }
  @media screen and (min-width: 928px) {
    display: block;
  }
`;

const SaveBtn = styled(FillBtn)`
  border-radius: 0.5rem;
  margin-top: 1rem;
  font-size: var(--text-4);
`;

function NewPassword() {
  const { dispatch, state } = useContext(Context);

  const closeModal = () => dispatch({ type: actions.CLOSE_LOGIN });

  const [confirm, setConfirm] = useState("");
  const [password, setPassword] = useState("");

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (password === confirm) {
      try {
        const { data } = await axios.post(
          getApiEndpoint("forgot-pass"),
          {
            password: password,
          },
          { headers: { authorization: `Bearer ${state.otp_token}` } }
        );
        dispatch({
          type: actions.CHANGE_STAGE,
          payload: { state: "login", otp_token: state.otp_token },
        });
      } catch (error) {}
    } else {
      console.log("Password are not the same");
    }
  };

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      onClick={closeModal}
    >
      <InnerContainer
        onClick={(e) => e.stopPropagation()}
        initial={{ y: "120%" }}
        animate={{ y: 0, transition: { duration: 0.5, delay: 0.25 } }}
      >
        <Form>
          <h2>Create New Password</h2>
          <p>Create your new password here.</p>
          <InputContainer>
            <label htmlFor="password">New Password</label>
            <input
              type="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </InputContainer>
          <InputContainer>
            <label htmlFor="confirm_password">Confirm Password</label>
            <input
              style={{
                border: confirm !== password ? "solid 1.5px tomato" : "",
              }}
              type="password"
              name="confirm_password"
              value={confirm}
              onChange={(e) => setConfirm(e.target.value)}
            />
          </InputContainer>
          <SaveBtn onClick={handleResetPassword}>SAVE</SaveBtn>
          <MobileCloseBtn onClick={closeModal}>
            <img src={CloseIcon} alt="close icon" />
          </MobileCloseBtn>
        </Form>
        <Image>
          <img src={LoginImage} alt="" />
        </Image>
      </InnerContainer>
    </Container>
  );
}

export default NewPassword;

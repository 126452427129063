import { getImageUrl } from "lib/getRestApi";
import React from "react";
import styled from "styled-components";

const Menu = styled.div`
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.5rem;
  position: relative;
  min-height: 4rem;
  border: 1px solid var(--primary-color);
  padding: 0 0.5rem;
  width: 100%;
  padding-left: 1rem;
  .image {
    position: absolute;
    left: -2.25rem;
    width: 5.5rem;
    aspect-ratio: 1 / 1;
    top: 60%;
    transform: translateY(-50%);
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .text {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    margin-left: 4rem;
    padding-block: 0.25rem;
    @media screen and (min-width: 992px) {
      margin-left: 5rem;
    }
  }

  h4 {
    font-size: var(--text-2);
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    line-height: 1.2;
    width: 100%;
    @media screen and (min-width: 786px) {
      font-size: var(--text-4);
    }

    @media screen and (min-width: 992px) {
      font-size: var(--text-6);
    }
  }

  strong {
    color: var(--primary-color);
    font-size: 10px;
    text-align: right;
    white-space: nowrap;
    font-size: var(--text-1);
    @media screen and (min-width: 786px) {
      font-size: var(--text-3);
    }
    @media screen and (min-width: 992px) {
      font-size: var(--text-4);
    }
  }

  @media screen and (min-width: 786px) {
    padding: 0 1.4rem;
    margin-top: 1rem;
    .image {
      left: -3rem;
      top: 55%;
    }
  }

  @media screen and (min-width: 992px) {
    padding: 0 2rem;
    margin-left: 1rem;
    height: 7rem;
    .image {
      left: -5rem;
      width: 10rem;
    }
  }
`;

export default function MenuItem({ item }) {
  return (
    <Menu>
      <div className="image">
        <img src={getImageUrl(item.photo)} alt={item.name} />
      </div>
      <div className="text">
        <h4>{item.name}</h4>
        <strong>{item.price} MMK</strong>
      </div>
    </Menu>
  );
}

import styled from "styled-components";
import { Link, NavLink, useLocation } from "react-router-dom";
import { motion } from "framer-motion";

import { ReactComponent as IconAccount } from "assets/icons/profile.svg";
import { ReactComponent as IconCart } from "assets/icons/add-to-cart.svg";
import Logo from "assets/images/logo.svg";
import { useContext, useEffect, useState } from "react";
import { Context } from "lib/store";
import * as actions from "lib/store/actions";

import useDevice from "lib/device";

const leftNavItems = [
  {
    href: "/",
    label: "Home",
  },
  {
    href: "/menu",
    label: "Menu",
  },
  {
    href: "/products",
    label: "products",
  },
  {
    href: "/blogs",
    label: "blogs",
  },
  {
    href: "/recipes",
    label: "recipes",
  },
];

const Header = styled(motion.header)`
  height: var(--header-height);
  background-color: #222222;
  border-bottom: 1px solid #505050;
  transition: height 0.2s ease;

  .hide {
    position: fixed;
    z-index: 150;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: transparent;
    padding-top: 7.95rem;
    transform: translateX(-100%);
    transition: ease-in 400ms transform;
    width: 100vw;
    height: max-content;
    ul {
      padding-left: 1rem;
      background-color: #222222;
      color: white;
      padding: 1.5rem;
      width: 100%;
      max-width: 25rem;
      height: 120vh;
      border-radius: 0.5rem;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      font-weight: bold;
      .dropdown-link {
        text-decoration: none;
        text-transform: none;
        padding: 0;
        color: white;
        white-space: nowrap;
        font-weight: bold;
        cursor: pointer;
      }
      .log-out {
        padding-top: 1rem;
        border-top: solid 1px var(--primary-color);
        color: #eeeeee;
        font-weight: bolder;
      }
    }
  }
  .slide {
    transition: ease-in 400ms transform;
    transform: translateX(0rem);
  }
`;

const Nav = styled.nav`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 94%;
  max-width: var(--max-width);
  margin: 0 auto;
  @media screen and (max-width: 726px) {
    padding-inline: 0.25rem;
  }
  .active-link-bottom {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 3px;
      height: 3px;
      border-radius: 100vw;
      transform: translateX(-50%);
      background-color: var(--primary-color);
    }
  }
`;
const UL = styled.ul`
  display: flex;
  gap: 4rem;
  li a {
    font-family: var(--family-h-medium);
    color: #afafaf;
    font-size: var(--text-4);
    text-decoration: none;
    text-transform: uppercase;
    padding: 5px;
    text-align: center;
    white-space: nowrap;
  }
  li {
    &:last-child {
      margin-right: 0;
    }
  }
`;
const LeftNavs = styled(UL)`
  align-items: center;
`;
const RightNavs = styled(UL)`
  align-items: center;
`;
const CenteredLogo = styled.div`
  overflow: hidden;
  flex-shrink: 0;
  img {
    max-height: calc(var(--header-height) + 0.5rem);
  }
  p {
    margin-top: -2.5rem;
    color: var(--primary-color);
    font-size: var(--text-1);
    text-align: center;
    display: none;
  }
  @media screen and (min-width: 928px) {
    margin-bottom: 1.5rem;
    p {
      display: block;
    }
    img {
      margin-top: -2.5rem;
    }
  }
`;

const HamburgerMenu = styled.div`
  --h-menu-size: 35px;
  width: var(--h-menu-size);
  height: var(--h-menu-size);
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform: scale(0.8);
  & > span {
    display: block;
    height: 4.5px;
    border-radius: 1rem;
    background-color: white;
    margin: 0.25rem;
  }
`;

const Login = styled.li`
  font-family: var(--family-h-medium);
  color: #afafaf;
  font-size: var(--text-4);
  cursor: pointer;
`;

const CartIcon = styled.li`
  position: relative;
  list-style: none;
  margin-top: 0.5rem;
  .qty {
    border-radius: 100vw;
    width: 2rem;
    height: 2rem;
    background-color: var(--primary-color);
    color: white;
    display: grid;
    place-content: center;
    font-size: 1.3rem;
    position: absolute;
    right: -1rem;
    top: -0.5rem;
  }
  .hide {
    display: none;
  }
  @media screen and (min-width: 928px) {
    .qty {
      right: -0.5rem;
      top: -0.25rem;
    }
    margin-top: 0rem;
  }
`;

const ActiveLink = (props) => {
  return (
    <NavLink
      style={({ isActive }) => {
        return {
          display: "block",
          color: isActive ? "var(--primary-color)" : "#afafaf",
        };
      }}
      {...props}
      className={({ isActive }) => (!isActive ? "" : " active-link-bottom")}
    >
      {props.children}
    </NavLink>
  );
};

export default function HeaderSection() {
  const device = useDevice();

  return (
    <Header
      initial={{
        y: -100,
      }}
      animate={{
        y: 0,
      }}
    >
      <Nav>
        {device !== "xlg" && <MobileMenu />}
        {device === "xlg" && <LeftNavLinks />}
        <Link to={"/"}>
          <CenteredLogo>
            <img src={Logo} alt="Mountaineer Coffee logo" />
            <p>100% Single Origin Arabica Coffee</p>
          </CenteredLogo>
        </Link>
        {device === "xlg" && <RightNavLinks />}
        {device !== "xlg" && <MobileCart />}
      </Nav>
    </Header>
  );
}

function MobileMenu() {
  const [clicked, setClicked] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const { state, dispatch } = useContext(Context);
  const { pathname } = useLocation();
  const handleLogOut = () => {
    localStorage.clear("token");
    localStorage.clear("user");
    window.location.reload();
  };
  useEffect(() => {
    const timeout = setTimeout(() => setClicked(false), 100);
    return () => clearTimeout(timeout);
  }, [pathname]);
  const handleLogin = () => dispatch({ type: actions.LOGGIN_IN });
  return (
    <>
      <HamburgerMenu onClick={() => setClicked(!clicked)}>
        <span></span>
        <span></span>
        <span></span>
      </HamburgerMenu>
      <div
        className={clicked ? "slide hide" : "hide"}
        onClick={() => setClicked(false)}
      >
        <ul onClick={(e) => e.stopPropagation()}>
          <Link className="dropdown-link" to={"menu"}>
            <li>MENU</li>
          </Link>
          <Link className="dropdown-link" to={"/products"}>
            <li>PRODUCTS</li>
          </Link>
          <Link className="dropdown-link" to={"/blogs"}>
            <li>BLOGS</li>
          </Link>
          <Link className="dropdown-link" to={"/recipes"}>
            <li>RECIPES</li>
          </Link>
          <Link className="dropdown-link" to={"/gallery"}>
            <li>GALLERY</li>
          </Link>
          <Link className="dropdown-link" to={"/about"}>
            <li>ABOUT US</li>
          </Link>
          <Link className="dropdown-link" to={"/contact"}>
            <li>CONTACT</li>
          </Link>
          {state?.token ? (
            <>
              <Link className="dropdown-link" to={"/profile"}>
                <li>MY ACCOUNT</li>
              </Link>
              <Link className="dropdown-link" to={"/order-list"}>
                <li>ORDER LIST</li>
              </Link>
              <li
                className="log-out dropdown-link"
                onClick={() => {
                  setShowLogoutModal(true);
                  setClicked(false);
                  document.body.style.overflow = "hidden";
                }}
              >
                LOG OUT
              </li>
            </>
          ) : (
            <li className="log-out dropdown-link" onClick={handleLogin}>
              LOG IN
            </li>
          )}
        </ul>
      </div>
      {showLogoutModal && (
        <LogoutModal
          handleLogOut={handleLogOut}
          setShowLogoutModal={setShowLogoutModal}
        />
      )}
    </>
  );
}

function MobileCart() {
  const { state } = useContext(Context);
  return (
    <CartIcon>
      <ActiveLink to="/cart">
        {state?.cartLength > 0 ? (
          <div className="qty">{state?.cartLength}</div>
        ) : state?.cartLength > 9 ? (
          <div className="qty">9+</div>
        ) : (
          <div className="hide"></div>
        )}
        <IconCart />
      </ActiveLink>
    </CartIcon>
  );
}

function LeftNavLinks() {
  return (
    <LeftNavs>
      {leftNavItems.map((item) => (
        <li key={item.label}>
          <ActiveLink to={item.href}>{item.label}</ActiveLink>
        </li>
      ))}
    </LeftNavs>
  );
}

function RightNavLinks() {
  const { state, dispatch } = useContext(Context);
  const handleLogin = () => dispatch({ type: actions.LOGGIN_IN });
  const handleLogOut = () => {
    localStorage.clear("token");
    localStorage.clear("user");
    window.location.reload();
  };
  return (
    <RightNavs>
      <li>
        <ActiveLink to="/gallery">Gallery</ActiveLink>
      </li>
      <li>
        <ActiveLink to="/about">About Us</ActiveLink>
      </li>
      <li>
        <ActiveLink to="/contact">contact</ActiveLink>
      </li>
      <MobileCart />
      {state?.token ? (
        <ProfileDropdown handleLogOut={handleLogOut} />
      ) : (
        <Login onClick={handleLogin}>LOGIN</Login>
      )}
    </RightNavs>
  );
}

const Profile = styled.li`
  position: relative;
  cursor: pointer;
  .dropdown {
    position: absolute;
    z-index: 150;
    right: 100%;
    background-color: #222222;
    color: white;
    padding: 1.5rem;
    width: 15rem;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
    .dropdown-link {
      text-transform: none;
      padding: 0;
      color: white;
      white-space: nowrap;
      position: relative;
      z-index: 1;
    }
    .log-out {
      width: 100%;
      padding-top: 0.5rem;
      border-top: solid 1px var(--primary-color);
      color: #eeeeee;
      font-weight: bolder;
      font-size: var(--text-4);
    }
  }
`;

const ProfileDropdown = ({ handleLogOut }) => {
  const [clicked, setClicked] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  return (
    <Profile>
      <IconAccount onClick={() => setClicked(!clicked)} />
      {clicked && (
        <>
          <ul className="dropdown">
            <Link className="dropdown-link" to={"/profile"}>
              <li onClick={() => setClicked(false)}>My Account</li>
            </Link>
            <Link className="dropdown-link" to={"/order-list"}>
              <li onClick={() => setClicked(false)}>Order Lists</li>
            </Link>
            <li
              className="log-out dropdown-link"
              onClick={() => {
                setShowLogoutModal(true);
                setClicked(false);
                document.body.style.overflow = "hidden";
              }}
            >
              Log out
            </li>
          </ul>
        </>
      )}
      {showLogoutModal && (
        <LogoutModal
          handleLogOut={handleLogOut}
          setShowLogoutModal={setShowLogoutModal}
        />
      )}
    </Profile>
  );
};

const LogOutModalContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: grid;
  place-items: center;
  position: fixed !important;
  inset: 0;
  .logout-modal {
    width: 100%;
    max-width: 35rem;
    padding: 2rem 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    background-color: white;
    h3 {
      font-size: 20px;
    }
    .btns {
      display: flex;
      align-items: center;
      gap: 2rem;
      button {
        width: 100%;
        max-width: 12rem;
        padding: 0.5rem 2rem;
        cursor: pointer;
        font-size: 16px;
        border: solid 2px var(--primary-color);
        background-color: transparent;
        color: var(--primary-color);
      }
      .logout-btn {
        color: white;
        background-color: var(--primary-color);
      }
    }
  }
`;

const LogoutModal = ({ handleLogOut, setShowLogoutModal }) => {
  return (
    <LogOutModalContainer
      onClick={() => {
        setShowLogoutModal(false);
        document.body.style.overflow = "unset";
      }}
    >
      <div className="logout-modal" onClick={(e) => e.stopPropagation()}>
        <h3>Are you sure to log out?</h3>
        <div className="btns">
          <button
            type="button"
            onClick={() => {
              setShowLogoutModal(false);
              document.body.style.overflow = "unset";
            }}
          >
            Cancel
          </button>
          <button type="button" className="logout-btn" onClick={handleLogOut}>
            Logout
          </button>
        </div>
      </div>
    </LogOutModalContainer>
  );
};

import SectionInfo from '../utils/SectionInfo';
import styled from 'styled-components';
import { MaxWidth } from 'components/MaxWidth';

import LogoVirtues from 'assets/images/logo-virtues.png';
import LogoProcessed from 'assets/images/logo-processed.png';
import LogoFarmed from 'assets/images/logo-farmed.png';
import LogoRoasted from 'assets/images/logo-roasted.png';
import LogoBrewed from 'assets/images/logo-brewed.png';

const stages = [
  {
    id: 1,
    image: LogoVirtues,
  },
  {
    id: 2,
    image: LogoProcessed,
  },
  {
    id: 3,
    image: LogoFarmed,
  },
  {
    id: 4,
    image: LogoRoasted,
  },
  {
    id: 5,
    image: LogoBrewed,
  },
];

const IntroSection = styled.section`
  padding-block: 2rem;
  background-color: transparent;
`;

const Wrapper = styled(MaxWidth)`
  padding-block: 3rem;
  @media screen and (min-width: 1200px) {
    padding-block: 5rem;
  }
`;

const Stages = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: max-content;
  .stage-img {
    width: 10rem;
  }
  @media screen and (min-width: 928px) {
    gap: 5rem;
    .stage-img {
      width: 17rem;
    }
  }
`;

const FourStages = () => {
  return (
    <Wrapper>
      <IntroSection>
        <SectionInfo title="Five Stages Of Coffee" />
      </IntroSection>
      <Stages>
        {stages.map((item) => (
          <img
            src={item.image}
            alt={item.image}
            key={item.id}
            className="stage-img"
          />
        ))}
      </Stages>
    </Wrapper>
  );
};

export default FourStages;

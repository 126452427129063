import styled from "styled-components";
import { motion } from "framer-motion";
import { useContext, useState } from "react";
import { Context } from "lib/store";
import * as actions from "lib/store/actions";
import CloseIcon from "../../assets/icons/close.png";
import { FillBtn } from "components/utils/Button";
import axios from "axios";
import { getApiEndpoint } from "lib/getRestApi";

import LoginImage from "../../assets/images/login.JPG";

const Container = styled(motion.div)`
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 110;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10rem;
`;

const InnerContainer = styled(motion.div)`
  width: 100%;
  max-width: 95vw;
  background-color: white;
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 10rem;
  min-height: 70vh;
  max-height: 90vh;

  @media screen and (min-width: 928px) {
    max-width: 60vw;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 6rem;
  }
`;

const Form = styled.form`
  position: relative;
  width: 100%;
  padding: 2.5rem 1.5rem;
  padding-bottom: 4rem;
  font-family: var(--family-h-medium);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1.5rem;
  color: #222222;
  button {
    cursor: pointer;
  }
  @media screen and (min-width: 928px) {
    padding: 2.5rem 6rem;
  }
`;

const OtpContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  margin-block: 2rem;
  input {
    width: 3.5rem;
    aspect-ratio: 1 / 1;
    border: solid 1.5px #707070;
    border-radius: 2px;
    text-align: center;
    font-size: var(--text-6);
  }
  @media screen and (min-width: 928px) {
    input {
      width: 4rem;
    }
  }
`;

const MobileCloseBtn = styled.button`
  position: absolute;
  top: 1.5rem;
  right: 0;
  background-color: transparent;
  border: none;
  transform: scale(0.3);
  @media screen and (min-width: 928px) {
    display: none;
  }
`;

const Image = styled.div`
  width: 100%;
  object-fit: cover;
  display: none;
  max-height: 90vh;

  img {
    width: 100%;
    height: 100%;
  }
  @media screen and (min-width: 928px) {
    display: block;
  }
`;

const ConfirmBtn = styled(FillBtn)`
  border-radius: 0.5rem;
  margin-top: 1rem;
  font-size: var(--text-4);
`;

const ResendBtn = styled.button`
  width: max-content;
  text-align: center;
  color: var(--primary-color);
  text-decoration: underline;
  font-size: var(--text-4);
  margin-inline: auto;
  border: none;
  background-color: transparent;
`;

function Verification() {
  const [otp, setOtp] = useState({
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
    otp5: "",
    otp6: "",
  });

  const { dispatch, state } = useContext(Context);

  const closeModal = () => dispatch({ type: actions.CLOSE_LOGIN });

  const handleChange = (otpNum, e) =>
    setOtp({ ...otp, [otpNum]: e.target.value });

  const inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else {
      const next = elmnt.target.tabIndex;
      if (next < 6) {
        elmnt.target.form.elements[next].focus();
      }
    }
  };

  const handleOtpConfirm = async (e) => {
    e.preventDefault();
    try {
      const otpcode = `${otp.otp1}${otp.otp2}${otp.otp3}${otp.otp4}${otp.otp5}${otp.otp6}`;
      const { data } = await axios.post(
        getApiEndpoint("verify-otp"),
        {
          otp_code: otpcode,
        },
        { headers: { authorization: `Bearer ${state.otp_token}` } }
      );
      console.log(data);
      if (data?.message === "success") {
        dispatch({
          type: actions.CHANGE_STAGE,
          payload: { state: "new_password", otp_token: state.otp_token },
        });
      }
    } catch (error) {}
  };

  const resendOtp = (e) => {
    e.preventDefault();
    dispatch({
      type: actions.CHANGE_STAGE,
      payload: { state: "forgot" },
    });
  };

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      onClick={closeModal}
    >
      <InnerContainer
        onClick={(e) => e.stopPropagation()}
        initial={{ y: "120%" }}
        animate={{ y: 0, transition: { duration: 0.5, delay: 0.25 } }}
      >
        <Form>
          <h2>Verification</h2>
          <p>Please, Check your mail. Enter the OTP code we sent here.</p>
          <OtpContainer>
            <input
              autoFocus
              name="otp1"
              type="text"
              autoComplete="off"
              value={otp.otp1}
              onChange={(e) => handleChange("otp1", e)}
              tabIndex="1"
              maxLength="1"
              onKeyUp={(e) => inputfocus(e)}
            />
            <input
              name="otp2"
              type="text"
              autoComplete="off"
              value={otp.otp2}
              onChange={(e) => handleChange("otp2", e)}
              tabIndex="2"
              maxLength="1"
              onKeyUp={(e) => inputfocus(e)}
            />
            <input
              name="otp3"
              type="text"
              autoComplete="off"
              value={otp.otp3}
              onChange={(e) => handleChange("otp3", e)}
              tabIndex="3"
              maxLength="1"
              onKeyUp={(e) => inputfocus(e)}
            />
            <input
              name="otp4"
              type="text"
              autoComplete="off"
              value={otp.otp4}
              onChange={(e) => handleChange("otp4", e)}
              tabIndex="4"
              maxLength="1"
              onKeyUp={(e) => inputfocus(e)}
            />
            <input
              name="otp5"
              type="text"
              autoComplete="off"
              value={otp.otp5}
              onChange={(e) => handleChange("otp5", e)}
              tabIndex="5"
              maxLength="1"
              onKeyUp={(e) => inputfocus(e)}
            />
            <input
              name="otp6"
              type="text"
              autoComplete="off"
              value={otp.otp6}
              onChange={(e) => handleChange("otp6", e)}
              tabIndex="6"
              maxLength="1"
              onKeyUp={(e) => inputfocus(e)}
            />
          </OtpContainer>
          <ConfirmBtn onClick={handleOtpConfirm}>CONFIRM CODE</ConfirmBtn>
          <ResendBtn onClick={resendOtp}>Resend code</ResendBtn>
          <MobileCloseBtn onClick={closeModal}>
            <img src={CloseIcon} alt="close icon" />
          </MobileCloseBtn>
        </Form>
        <Image>
          <img src={LoginImage} alt="Mountaineer Coffee" />
        </Image>
      </InnerContainer>
    </Container>
  );
}

export default Verification;
